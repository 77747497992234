import React from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
const Success = (): JSX.Element => {
  const clickHandler = () => {
    navigate('/')
  }
  return (
    <PlanDetailsStyle>
      <span className="icon icon-lively-circled-check mr-2 text-lively-magenta"></span>
      <h1>You’re Covered!</h1>
      <p>
        Thank you for signing up for the Lively Protection Plan. You’ll see your
        new coverage reflected on your next billing statement.
      </p>
      <button type="submit" onClick={clickHandler}>
        Explore Lively.com
      </button>
    </PlanDetailsStyle>
  )
}

export default Success

const PlanDetailsStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  height: 60vh;
  justify-content: center;
  .icon {
    font-size: 43px;
  }
  h1 {
    margin: 1rem 0;
  }

  button {
    background: linear-gradient(46.91deg, #e60046 0%, #ce1578 117.6%);
    border-radius: 6px;
    height: 48px;
    font-size: 18px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #ffffff;
    width: 184px;
  }
`
